import classNames from "classnames";
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useGetAllTagsQuery, useGetTagsLazyQuery, useUpdateTagsMutation } from "../../generated/graphql";
import { notify } from "../../store/function";
import { ActionButton, CancelButton, UpdateButton } from "../button";
import { ClassNames } from "../classes";
import { IDropdownItem } from "../dropdown";
import { Loading } from "../loading";
import { Search } from "../search";
import { Icons } from "../../config/icons";
import { Icon } from "../card";


type ITagCard = {
    id: string;
    type: string;
    onCancel?: () => void;
    bgClassName?: string;
}

export const TagCard: FC<ITagCard> = ({ id, type, onCancel, bgClassName }) => {
    const { data: allTags } = useGetAllTagsQuery();
    const [getTags, { loading }] = useGetTagsLazyQuery({
        variables: {
            id,
            type,
        }
    });
    const [updateTags, { loading: updateTagsLoading }] = useUpdateTagsMutation();
    const [searchText, setSearchText] = useState("");
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        getTags({
            onCompleted(data) {
                setTags(data.Tag);
            },
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        if (e.target.value.length <= 1) {
            return;
        }
    }, []);

    const handleUpdate = useCallback(() => {
        if (updateTagsLoading) {
            return;
        }
        updateTags({
            variables: {
                id,
                type,
                tags,
            },
            onCompleted(data) {
                notify("Tags updated successfully", "success");
                setTags(data.UpdateTags);
            },
        });
    }, [id, type, tags, updateTags, updateTagsLoading]);

    const handleSelect = useCallback((item: IDropdownItem) => {
        setTags(t => [item.id, ...t,]);
        setSearchText("");
    }, []);

    const handleAddTag = useCallback(() => {
        setSearchText("");
        setTags(ts => [searchText, ...ts]);
    }, [searchText]);

    const handleDeleteTag = useCallback((tag: string) => {
        setTags(ts => ts.filter(t => t !== tag));
    }, []);

    const items = useMemo(() => {
        return allTags?.Tags.map(tag => ({ id: tag, label: tag })) ?? [];
    }, [allTags]);

    return <div className="flex flex-col overflow-hidden grow">
        <div className={classNames(ClassNames.Text, "mt-4")}>
            Grouping Tags
        </div>
        {
            loading
            ? <Loading />
            : <>
                <Search label="Search tags..." controlled={true} isOpen={searchText.length >= 1} items={items} inputProps={{
                    value: searchText,
                    onChange: handleSearchChange,
                }} onSelect={handleSelect} noItemsLabel="No users found..." actionLabel="Share" className="mt-2"
                    dropdownProps={{
                        defaultItem: {
                            label: `Add new label: ${searchText}`,
                            icon: Icons.Add,
                        },
                        onDefaultItemClick: handleAddTag,
                    }} />
                <div className="overflow-y-auto mb-4 grow">
                    <div className="flex flex-col gap-2 mt-2 overflow-y-auto">
                        {tags.map(tag => (
                            <div key={tag} className="flex justify-between items-center overflow-hidden">
                                <div className={classNames(ClassNames.Text, "text-base pr-2 py-1 rounded-lg flex items-center gap-2")}>
                                    <Icon className="w-6 h-6" component={Icons.Tag} bgClassName={classNames(bgClassName, "h-[30px] w-[30px]")} />
                                    {tag}
                                </div>
                                <ActionButton icon={Icons.Delete} containerClassName="shadow-sm border-0 h-8 w-8 m-0 self-center" className="transition-all stroke-red-500 dark:stroke-red-600 brightness-75 hover:brightness-100" onClick={() => handleDeleteTag(tag)} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="flex justify-between items-center w-full">
                        <CancelButton onClick={onCancel} />
                        <UpdateButton loading={updateTagsLoading} onClick={handleUpdate} />
                    </div>
                </div>
            </>
        }
    </div>
}