import { FC, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Page, PageList } from "../../components/container";
import { InternalRoutes } from "../../config/internal-routes";
import { useGetIntegrationsQuery } from "../../generated/graphql";
import { notify } from "../../store/function";
import { getOAuthUrlCallbackLocationStorageCacheId, OAuthElements } from "./common/oauth-login-card";
import { DIGITAL_OCEAN_INTEGRATION_ICON, DigitalOceanIntegrationsCard } from "./digital-ocean-integrations/digital-ocean-integrations-card";
import { DOCKER_IO_REGISTRY_ICON, DockerIOIntegrationsCard } from "./docker-io-integrations/docker-io-integrations-card";
import { GITHUB_INTEGRATION_ICON, GitHubIntegrationsCard } from "./github-integrations/github-integrations-card";
import { GOOGLE_INTEGRATION_ICON, GoogleIntegrationsCard } from "./google/google-integrations-card";
import { CreateIntegrationsCard } from "./integrations-card";
import { MAIL_ICON, MailIntegrationsCard } from "./mail/mail-integrations-card";

type IIntegrationsPageProps = {
    isEmbedded?: boolean;
    isCreating?: boolean;
}

export const IntegrationsPage: FC<IIntegrationsPageProps> = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data, refetch } = useGetIntegrationsQuery();

    useEffect(() => {
        if (searchParams.has("success")) {
            const successMessage = searchParams.get("success")!;
            if (searchParams.has("id") && searchParams.has("type")) {
                localStorage.setItem(getOAuthUrlCallbackLocationStorageCacheId(searchParams.get("type") as OAuthElements), JSON.stringify({
                    id: searchParams.get("id"),
                    type: "success",
                    message: successMessage,
                }));
            } else {
                searchParams.delete("success");
                searchParams.delete("id");
                setSearchParams(searchParams);
                notify(successMessage, "success");
            }
        }
        if (searchParams.has("error")) {
            const errorMessage = searchParams.get("error")!;
            notify(errorMessage, "error");
            localStorage.setItem(getOAuthUrlCallbackLocationStorageCacheId(searchParams.get("type") as OAuthElements), JSON.stringify({
                id: searchParams.get("id"),
                type: "error",
                message: errorMessage,
            }));
            searchParams.delete("error");
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);

    const routes = useMemo(() => {
        const newRoutes = [InternalRoutes.Integrations.Integrations];
        if (props.isCreating || props.isEmbedded) {
            newRoutes.push(InternalRoutes.Integrations.CreateIntegrations);
        }
        return newRoutes;
    }, [props.isCreating, props.isEmbedded]);

    return (
        <Page routes={routes}>
            <CreateIntegrationsCard isCreating={props.isCreating || props.isEmbedded} refetch={refetch} />
            <PageList nodes={data?.Integration.DockerIORegistryIntegration}
                renderNode={dockerIORegistry => <DockerIOIntegrationsCard key={dockerIORegistry.Id} dockerIORegistry={dockerIORegistry} refetch={refetch} />}
                icon={DOCKER_IO_REGISTRY_ICON} />
            <PageList nodes={data?.Integration.GitHubIntegration}
                renderNode={githubIntegration => <GitHubIntegrationsCard key={githubIntegration.Id} githubIntegration={githubIntegration} refetch={refetch} />}
                icon={GITHUB_INTEGRATION_ICON} />
            <PageList nodes={data?.Integration.DigitalOceanIntegration}
                renderNode={digitalOceanIntegration => <DigitalOceanIntegrationsCard key={digitalOceanIntegration.Id} digitalOceanIntegration={digitalOceanIntegration} refetch={refetch} />}
                icon={DIGITAL_OCEAN_INTEGRATION_ICON} />
            <PageList nodes={data?.Integration.GoogleIntegration}
                renderNode={googleIntegration => <GoogleIntegrationsCard key={googleIntegration.Id} googleIntegration={googleIntegration} refetch={refetch} />}
                icon={GOOGLE_INTEGRATION_ICON} />
            <PageList nodes={data?.Integration.MailIntegration}
                renderNode={mailIntegration => <MailIntegrationsCard key={mailIntegration.Id} mailIntegration={mailIntegration} refetch={refetch} />}
                icon={MAIL_ICON} />
        </Page>
    )
}