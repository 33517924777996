import { FC, ReactNode, useCallback } from "react";
import { Icons } from "../config/icons";
import { GlobalActions } from "../store/global";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { ActionButton } from "./button";
import classNames from "classnames";

export const TOP_BAR_ACTION_CLASS_NAME = "topbar-actions";

export const GlobalProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const darkModeEnabled = useAppSelector(state => state.global.theme === "dark");
  const dispatch = useAppDispatch();

  const handleDarkModeToggle = useCallback(() => {
      dispatch(GlobalActions.setTheme(!darkModeEnabled ? "dark" : "light"));
  }, [darkModeEnabled, dispatch]);

  const handleRedirectDocs = useCallback(() => {
    // todo: change this to include current routes
    window.open("/docs", "_blank");
  }, []);

  return <div className={classNames({
        "dark": darkModeEnabled,
    })}>
        <div className="min-h-[100vh] w-[100vw] dark:bg-black/90">
            <div className="fixed top-6 right-6 flex gap-2 items-center z-30">
                <div className={TOP_BAR_ACTION_CLASS_NAME}></div>
                <ActionButton containerClassName="rounded-full w-8 h-8" className="w-6 h-6" icon={darkModeEnabled ? Icons.Sun : Icons.Moon} onClick={handleDarkModeToggle} />
                <ActionButton containerClassName="rounded-full w-8 h-8" className="w-5 h-5" icon={Icons.DocumentText} onClick={handleRedirectDocs} />
            </div>
            {children}
        </div>
    </div>
}