import { FC, useMemo } from "react";
import { Page, PageList } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { Cluster, useGetClustersQuery } from "../../../generated/graphql";
import { ClusterCard, CreateClusterCard, DEPLOY_CLUSTER_ICON } from "./cluster-card";

type IClusterPageProps = {
    isEmbedded?: boolean;
    isCreating?: boolean;
}

export const ClusterPage: FC<IClusterPageProps> = (props) => {
    const getAllClustersResponse = useGetClustersQuery();

    const routes = useMemo(() => {
        const newRoutes = [InternalRoutes.Deployment.Cluster];
        if (props.isCreating || props.isEmbedded) {
            newRoutes.push(InternalRoutes.Deployment.CreatCluster);
        }
        return newRoutes;
    }, [props.isCreating, props.isEmbedded]);

    return (
        <Page routes={routes}>
            <CreateClusterCard isCreating={props.isCreating || props.isEmbedded} refetch={getAllClustersResponse.refetch} />
            <PageList nodes={getAllClustersResponse.data?.Cluster} renderNode={cluster => <ClusterCard key={cluster.Id} cluster={cluster as Cluster} refetch={getAllClustersResponse.refetch} />}
                icon={DEPLOY_CLUSTER_ICON} />
        </Page>
    )
}