import { FC, useMemo } from "react";
import { Page, PageList } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { useGetEnvironmentVariablesQuery } from "../../../generated/graphql";
import { CONFIG_ENVIRONMENT_VARIABLE_ICON, CreateEnvironmentVariableCard, EnvironmentVariableCard, transformEnvironmentVariableData } from "./environment-variable-card";

type IEnvironmentVariablePageProps = {
    isEmbedded?: boolean;
    isCreating?: boolean;
}

export const EnvironmentVariablePage: FC<IEnvironmentVariablePageProps> = (props) => {
    const getAllEnvironmentVariablesResponse = useGetEnvironmentVariablesQuery();

    const routes = useMemo(() => {
        const newRoutes = [InternalRoutes.Config.EnvironmentVariable];
        if (props.isCreating || props.isEmbedded) {
            newRoutes.push(InternalRoutes.Config.CreatEnvironmentVariable);
        }
        return newRoutes;
    }, [props.isCreating, props.isEmbedded]);

    return (
        <Page routes={routes}>
            <CreateEnvironmentVariableCard isCreating={props.isCreating || props.isEmbedded} refetch={getAllEnvironmentVariablesResponse.refetch} />
            <PageList nodes={transformEnvironmentVariableData(getAllEnvironmentVariablesResponse.data)} renderNode={environmentVariable => <EnvironmentVariableCard key={environmentVariable.Id} data={environmentVariable} refetch={getAllEnvironmentVariablesResponse.refetch} />}
                                icon={CONFIG_ENVIRONMENT_VARIABLE_ICON} />
        </Page>
    )
}