import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type IGlobalState = {
  theme: "light" | "dark";
  sideMenuCollapsed: boolean;
  pageView: "list" | "group";
}

const initialState: IGlobalState = {
  theme: "dark",
  sideMenuCollapsed: false,
  pageView: "list",
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<IGlobalState["theme"]>) => {
        state.theme = action.payload;
    },
    setSideMenuCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sideMenuCollapsed = action.payload;
    },
    setPageView: (state, action: PayloadAction<IGlobalState["pageView"]>) => {
      state.pageView = action.payload;
    },
  },
});

export const GlobalActions = globalSlice.actions;
export const globalReducers = globalSlice.reducer;