import { FC, useMemo } from "react";
import { Page, PageList } from "../../../components/container";
import { InternalRoutes } from "../../../config/internal-routes";
import { RegistryImage, useGetRegistryImagesQuery } from "../../../generated/graphql";
import { CONTAINER_REGISTRY_IMAGE_ICON, CreateRegistryImageCard, RegistryImageCard } from "./registry-image-card";

type IRegistryImagePageProps = {
    isEmbedded?: boolean;
    isCreating?: boolean;
}

export const RegistryImagePage: FC<IRegistryImagePageProps> = (props) => {
    const getAllRegistryImagesQueryResponse = useGetRegistryImagesQuery();

    const routes = useMemo(() => {
        const newRoutes = [InternalRoutes.Container.Image];
        if (props.isCreating || props.isEmbedded) {
            newRoutes.push(InternalRoutes.Container.CreateImage);
        }
        return newRoutes;
    }, [props.isCreating, props.isEmbedded]);

    return <Page routes={routes}>
        <CreateRegistryImageCard isCreating={props.isCreating || props.isEmbedded} refetch={getAllRegistryImagesQueryResponse.refetch} />
        <PageList nodes={getAllRegistryImagesQueryResponse.data?.RegistryImage} renderNode={registryImage => <RegistryImageCard key={registryImage.Id} registryImage={registryImage as RegistryImage} refetch={getAllRegistryImagesQueryResponse.refetch} />}
            icon={CONTAINER_REGISTRY_IMAGE_ICON} />
    </Page>
}