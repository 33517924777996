import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Children, cloneElement, createContext, FC, isValidElement, ReactNode, useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { Icons } from "../config/icons";
import { GlobalActions, IGlobalState } from "../store/global";
import { useAppSelector } from "../store/hooks";
import { Breadcrumb, IBreadcrumbRoute } from "./breadcrumb";
import { ActionButton, ToggleButton } from "./button";
import { Card, ICardIcon, Icon } from "./card";
import { ClassNames } from "./classes";
import { Loading } from "./loading";
import { Pill } from "./pill";
import { Search } from "./search";
import { isArray } from "lodash";

type ContainerProps = {
    children: React.ReactElement;
}

export const Container: FC<ContainerProps> = ({ children }) => {
    const sideMenuCollapsed = useAppSelector(state => state.global.sideMenuCollapsed);

    return <div className="flex flex-1 flex-grow">
        <div className={classNames("absolute top-6 left-6 flex gap-1 items-center", {
            "hidden": sideMenuCollapsed,
        })}>
            <img src="/images/logo.svg" alt="logo" width="15" />
            <div className={classNames(ClassNames.Title, "text-md mt-[1px]")}>
                Clidey
            </div>
        </div>
        {children}
    </div>
}

const PageContext = createContext({ search: "" });

type PageProps = {
    className?: string;
    children: ReactNode;
    routes?: IBreadcrumbRoute[];
    flag?: string;
}

export const Page: FC<PageProps> = (props) => {
    const [search, setSearch] = useState("");
    const [filterFocused, setFilterFocused] = useState(false);
    const pageView = useAppSelector(state => state.global.pageView);
    const dispatch = useDispatch();
    const pathname = useLocation().pathname;
    const isEmbedded = useMemo(() => {
        const lastRoute = props.routes?.at(-1)?.path;
        if (lastRoute == null) {
            return false
        }
        return !matchPath(lastRoute, pathname);
    }, [pathname, props.routes]);
    
    const hasPageList = useMemo(() => {
        return Children.toArray(props.children).some(
            (child) => isValidElement(child) && child.type === PageList
        );
    }, [props.children]);

    if (isEmbedded) {
        return <div className={classNames("flex flex-row grow flex-wrap gap-2 justify-items-start content-start w-full max-h-[calc(100vh-100px)] overflow-y-auto", props.className)}>
            {props.children}
        </div>
    }

    return <PageContext.Provider value={{
        search,
    }}>
        <div className="flex px-8 pt-6 flex-col grow">
            <div className="flex items-center">
                <Breadcrumb routes={props.routes ?? []} active={props.routes?.at(-1)} />
            </div>
            <div className={classNames("flex w-full justify-end items-center mt-8 gap-2 translate-x-2", {
                "hidden": !hasPageList,
            })}>
                <Search className={classNames("rounded-full transition-all", {
                        "w-[34px]": !filterFocused,
                    })}
                    isOpen={false} items={[]} label={filterFocused ? "Filter" : ""} inputProps={{
                        className: "rounded-full transition-all",
                        onFocus: () => setFilterFocused(true),
                        onClick: () => setFilterFocused(true),
                        onBlur: () => setFilterFocused(false),
                        onChange: (e) => setSearch(e.target.value),
                        autoFocus: false,
                    }} icon={Icons.Filter} />
                <ToggleButton options={[ { id: "list", icon: Icons.List }, { id: "group", icon: Icons.Folder } ]} selected={pageView} onSelect={(id) => dispatch(GlobalActions.setPageView(id as IGlobalState["pageView"]))} />
            </div>
            <div className={classNames("flex flex-row grow flex-wrap gap-2 justify-items-start content-start w-full max-h-[calc(100vh-100px)] overflow-y-auto", props.className)}>
                {props.children}
            </div>
            <AnimatePresence>
                {
                    props.flag != null &&
                    <motion.div className="absolute bottom-4 right-4 cursor-default" 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 100, }}
                        exit={{ opacity: 0 }}>
                        <Pill id={props.flag} label={props.flag} className="bg-orange-600 text-white transition-all opacity-50 hover:opacity-100" />
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    </PageContext.Provider>
}

type PageNode = ({ Id: string, Name: string, Labels: string[] }&any);

const PageGroup: FC<{ 
    groupName: string, 
    nodes: PageNode[], 
    icon: ICardIcon, 
    renderNode: (node: PageNode) => ReactNode 
}> = ({ groupName, nodes, icon, renderNode }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="relative">
            <motion.div 
                layout 
                className={open ? "opacity-0 pointer-events-none" : "opacity-100"}
                transition={{ duration: 0.3 }}
            >
                <Card 
                    className="group/group-card cursor-pointer" 
                    key={groupName} 
                    icon={{
                        component: Icons.Folder,
                        bgClassName: icon?.bgClassName,
                    }} 
                    tag={
                        <div className="flex grow ml-2">
                            <div className={classNames(ClassNames.Text, "text-lg")}>
                                {groupName}
                            </div>
                        </div>
                    }
                    onClick={() => setOpen(true)}
                >
                    <div className="flex grow py-4 opacity-75 group-hover/group-card:opacity-100 gap-1 flex-wrap">
                        {nodes.slice(0, nodes.length >= 7 ? 5 : 6).map((node) => (
                            <Icon 
                                key={node.id} 
                                component={icon.component} 
                                className={twMerge(icon.className, "w-5 h-5")} 
                                bgClassName={twMerge(icon.bgClassName, "w-[50px] h-[50px]")} 
                            />
                        ))}
                        {nodes.length > 6 && <div className={classNames("flex justify-center items-center w-[50px] h-[50px] rounded-lg", icon.bgClassName, ClassNames.Text)}>
                            {cloneElement(Icons.Plus, {
                                className: "w-5 h-5",
                            })}
                            <div className="text-sm">{nodes.length - 6}</div>
                        </div>}
                    </div>
                </Card>
            </motion.div>
            <AnimatePresence mode="wait">
                {open && (
                    <motion.div
                        layoutId="modal"
                        initial={{ scale: 0.1, opacity: 0.4, top: 0, left: 0, }}
                        animate={{ 
                            scale: 1,
                            opacity: 1,
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            width: "60vw",
                            height: "60vh",
                            translateX: "-50%",
                            translateY: "-50%",
                        }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        transition={{ type: "spring", stiffness: 200, damping: 20 }}
                        className="backdrop-blur-lg p-6 rounded-xl shadow-lg z-50 flex flex-col gap-4 border border-black/5 dark:border-white/5 backdrop-brightness-90"
                    >
                        <div className="flex justify-between items-center">
                            <h2 className={classNames(ClassNames.Text, "text-xl font-bold")}>{groupName}</h2>
                            <ActionButton className="w-6 h-6" containerClassName="w-8 h-8 bg-white dark:bg-neutral-800" icon={Icons.Cross} onClick={() => setOpen(false)} />
                        </div>
                        <div className="flex gap-2 flex-wrap overflow-y-auto pb-8">
                            {nodes.map((node) => renderNode(node))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const PageListGroupView: FC<{ groups: [string, PageNode[]][], icon: ICardIcon, renderNode: (node: PageNode) => ReactNode }> = ({ groups, icon, renderNode }) => {
    return <AnimatePresence mode="sync">
        {groups.map(([groupName, nodes]) => (
            <PageGroup key={groupName} groupName={groupName} nodes={nodes} icon={icon} renderNode={renderNode} />
        ))}
    </AnimatePresence>
}

export const PageList: FC<{ nodes?: PageNode[], renderNode: (node: PageNode) => ReactNode, icon: ICardIcon }> = ({ nodes, renderNode, icon }) => {
    const pageView = useAppSelector(state => state.global.pageView)
    const { search } = useContext(PageContext);
    
    const filteredNodes = useMemo(() => {
        const lowerCaseSearch = search.toLowerCase();
        return nodes?.filter(node => node.Name.toLowerCase().includes(lowerCaseSearch)) ?? [];
    }, [nodes, search]);

    const {groups, ungrouped} = useMemo(() => {
        const ungrouped: PageNode[] = [];
        const filteredNodeMap: {[key: string]: PageNode[]} = {};
        for (const node of filteredNodes) {
            if (node.Labels.length === 0) {
                ungrouped.push(node);
                continue;
            }
            for (const label of node.Labels) {
                if (!(label in filteredNodeMap)) {
                    filteredNodeMap[label] = [node];
                } else if (filteredNodeMap[label]?.find(n => n.Id === node.Id) == null) {
                    filteredNodeMap[label] = [...(filteredNodeMap[label] ?? []), node];
                }
            }
        }
        return {groups: Object.entries(filteredNodeMap), ungrouped };
    }, [filteredNodes]);
    
    if (pageView === "group") {
        return <>
            <PageListGroupView groups={groups} icon={icon} renderNode={renderNode} />
            {ungrouped.map(node => renderNode(node))}
        </>
    }

    return <>
        {filteredNodes.map(node => renderNode(node))}
    </>
}

export const LoadingPage: FC = () => {
    return  <div className="fixed inset-0 flex h-[100vh] w-[100vw] justify-center items-center backdrop-blur-md">
    <Loading />
  </div>
}